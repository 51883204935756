// See: https://github.com/nuxt/nuxt/issues/13020#issuecomment-1397282762
import { type CookieRef } from "#app";

// Expires in a year
const expires = new Date();
expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);

// Make cookie use state across app
export default function useStatefulCookie<T = string>(
  name: string,
  defaultValue?: T
): CookieRef<T> {
  const cookie = useCookie<T>(name, { expires });
  if (defaultValue) cookie.value = defaultValue;

  const state = useState<T>(name, () => cookie.value);

  watch(
    state,
    () => {
      cookie.value = state.value;
    },
    { deep: true }
  );

  return state;
}
